import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Seo from "../components/seo";
import HeadingWithSub from "../components/HeadingWithSub";
import ParentRouteHandler from "../components/ParentRouteHandler";
import Row from "../components/Row";
import icon1 from "../images/icon-wheelchair.svg";
import icon2 from "../images/icon-accessibility-tour.svg";
import icon3 from "../images/icon-large-print.svg";
import TextList from "../components/TextList";
import { Link } from "gatsby";

const AccessibilityGuide = ({ location }) => {
  const isNavigatedFromNavMenu = location.search.includes("from-nav=1");
  const textObject = [
    {
      obj: [
        <p>
          Audio tours are available on the free{" "}
          <Link to="/">mobile museum guide.</Link> Transcripts are provided on
          the webpage of each audio tour. Physical transcripts are available on
          request. Ask any Visitor Experience Team Member upon arrival.
        </p>,
      ],
    },
    {
      obj: [
        <p>
          For visitors on the autism spectrum, we’ve created a social narrative
          to help you know what to expect on your visit.
        </p>,
      ],
    },
    {
      obj: [
        <p>
          A few artworks on view have extra auditory and visual elements.
          Visitors who are uncomfortable with flashing lights and/or dark,
          enclosed spaces should bypass these artworks.
        </p>,
      ],
    },
    {
      obj: [
        <p>
          For those unable to join us in the Vault, we offer photos of the Vault
          windows from the staircase
        </p>,
      ],
    },
  ];
  return (
    <>
      <Seo title="Accessibility Guide" />
      {isNavigatedFromNavMenu && <ParentRouteHandler title={"back"} />}
      <HeadingWithSub main={"Accessibility Guide"} h2={true} />
      <StaticImage
        src="../images/accessibility.png"
        alt="Accessibility Guide"
      />
      <div style={{ marginBottom: 32, marginTop: 21 }}>
        <p style={{ fontSize: 16 }}>
          Welcome! The Broad is committed to making its collection, building,
          programs, and services accessible to all visitors.
        </p>

        <p style={{ fontSize: 16 }}>
          Please email{" "}
          <a href="mailto:info@thebroad.org" rel="noreferrer">
            info@thebroad.org
          </a>{" "}
          for accessibility questions or assistance.
        </p>
      </div>

      <Row
        text="<strong>Wheelchairs;</strong> all galleries and facilities are wheelchair accessible, and are available free of charge in the lobby on a first come, first served basis. Ask a stationed Visitor Experience team member."
        image={icon1}
        height="32"
        width="32"
      />
      <Row
        text="<strong>Accessibility Tours;</strong> Visual description tours and ASL interpreter tours are available upon request. Email <a href='mailto:groups@thebroad.org' rel='norefferer'>groups@thebroad.org</a> two weeks prior before your next preferred visit date."
        image={icon2}
        height="32"
        width="32"
      />
      <Row
        text="<strong>Large Print;</strong> Large-print gallery notes are available on request free of charge. Available in the Lobby or ask a Visitor Experience member."
        image={icon3}
        height="32"
        width="32"
      />
      <TextList title="Audio Tour Transcripts" subtitle={textObject[0].obj} link="/audio" />
      <TextList title="Social Narrative" subtitle={textObject[1].obj} link="https://www.thebroad.org/sites/default/files/2024-06/The Broad_Social Narrative 2021 (no C19)_Updated 6.6.24.pdf" />
      {/* <TextList title="Sensory Map" subtitle={textObject[2].obj} /> */}
      {/* <TextList title="View of the Vault" subtitle={textObject[3].obj} /> */}
    </>
  );
};

export default AccessibilityGuide;
