import React from "react";
import styled from "@emotion/styled";
import { colors } from "../utilities/themeVars";
import { Link } from "gatsby";
import Arrow from "./Arrow";

const ATagType = ({
  link,
  children,
  newTab,
  isPdf,
  originPath,
  origin,
  title,
}) =>
  isPdf ? (
    <Link
      to="/reader"
      state={{
        pdf: link,
        originalPath: originPath,
        origin: origin,
        title: title,
      }}
    >
      {children}
    </Link>
  ) : (
    <Link to={link}>{children}</Link>
  );

const Container = styled.div`
  > a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 0;
    border-bottom: solid 1px ${colors.gray3};
  }
`;

const Left = styled.div`
  margin-right: 10px;
  max-width: 82px;

  .img {
    max-width: 82px;
  }
`;

const Right = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  flex-grow: 1;

  .title {
    font-size: 12px;
    color: ${colors.primary};
    margin-bottom: 4px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1.2px;
    line-height: 15px;
  }

  .subtitle {
    color: initial;
    font-size: 14px;
  }
  .arrow {
    min-width: 9px;
    margin-left: 10px;
  }
`;

const TextList = ({
  title,
  subtitle,
  link = "/",
  newTab = false,
  isPdf = false,
  originPath = "",
  origin = "",
}) => {
  return (
    <Container>
      <ATagType
        link={subtitle !== "Coming Soon" ? link : ""}
        newTab={newTab}
        isPdf={isPdf}
        originPath={originPath}
        origin={origin}
        title={title}
      >
        <Right>
          <div>
            <p className="title">{title}</p>
            <div className="subtitle">{subtitle}</div>
          </div>
          <div className="arrow">
            <Arrow direction="right" />
          </div>
        </Right>
      </ATagType>
    </Container>
  );
};

export default TextList;
